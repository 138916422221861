@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --font-primary: 'Inter', sans-serif;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

.ql-toolbar.ql-snow,
.ql-container,
.ql-snow {
  border: none !important;
}

/* Hide the flag */
.PhoneInputCountry {
  display: none !important;
}

/* Hide the drop-down arrow next to the country code if needed */
.PhoneInputInputGroup {
  padding-left: 0; /* Adjust padding to make room for just the phone number */
}
